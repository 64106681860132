import React from 'react'
import {graphql, StaticQuery} from 'gatsby'
import AdHeaderAMP from "../ads/adHeader.amp";
import {STRINGS} from "../../constants/strings";
import {URL} from "../../constants/urls";
import {HeaderAMP} from '@cg-squad/ui-components';
import {FOLLOW_US} from "../../constants/followUs";

const HeaderWrapperAMP = (props) => {

    const logoSizes = {
        menuLogoWidth: 300,
        menuLogoHeight: 40,
        headerLogoWidth: 300,
        headerLogoHeight: 40
    }

    const renderNavItems = () => (
        <StaticQuery
            query={graphql`
          query HeaderCategoryQueryAMP {
            allCategories: allDatoCmsCategory(
                filter: {root: {eq: true}, website: {elemMatch: {name: {eq: "pt"}}}}
                sort: {order: ASC, fields: position}
              ) {
                nodes {
                  slug
                  title
                  children: treeChildren {
                    slug
                    title
                    position
                  }
                }
              }
            }
        `}
            render={data =>
                <HeaderAMP path={props.path}
                           categoryPath={props.categoryPath}
                           subCategoryPath={props.subCategoryPath}
                           AdHeaderAMP={AdHeaderAMP}
                           allCategories={data.allCategories}
                           stringsConst={STRINGS}
                           urlsConst={URL}
                           followUs={FOLLOW_US}
                           logoSizes={logoSizes}/>
            }
        />
    )

    return (
        <>
            {renderNavItems()}
        </>
    )
}

export default HeaderWrapperAMP
